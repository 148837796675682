document.addEventListener('click', function(e){

/**
 * Add product to the Wishlist
 */
    if(e.target && e.target.closest('#product-options-offcanvas') && e.target.closest('a.option-btn'))
    {
        e.preventDefault();

        const optionBtn        = e.target.closest('a.option-btn');
        const targetUrl        = optionBtn.href;
        const form             = e.target.closest('form');
        const formData         = new FormData(form);
        const optionsOffcanvas = document.getElementById('product-options-offcanvas');
        const productContainer = document.getElementById('product-information-container');
        const addShoppingCart  = document.getElementById('sticky-bottom-add-shopping-cart');
        const offcanvas        = optionsOffcanvas.getElementsByClassName('offcanvas-product-options-container')[0];
        
        formData.append('product_url', targetUrl);

        window.axios
            .post(form.action, formData)
            .then(function (response)
            {
                offcanvas.innerHTML        = response.data.offcanvas;
                productContainer.innerHTML = response.data.product;
                addShoppingCart.innerHTML  = response.data.cart;
            })
            .catch(error => {
                // alert(error.response.data.message);
            })
            .then(function(){
                // submitBtn.disabled = false;
            });

        history.pushState(null, '', decodeURIComponent(targetUrl));

        // const optionList = optionBtn.closest('div.option-list-container');
        // const optionListBtns = [].slice.call(optionList.querySelectorAll('a.option-btn'));

        // optionListBtns.map(function (btn) {
        //     btn.classList.remove('active');
        // });

        // optionBtn.classList.add('active');
    }

});