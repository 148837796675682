var filterForms          = [].slice.call(document.querySelectorAll('form.product-filter-form'));
var filteredProductCount = [].slice.call(document.getElementsByClassName('filtered-product-count'));


filterForms.map(function (filterForm) {

    var filterCheckboxes = [].slice.call(filterForm.querySelectorAll('input[type=checkbox]'));

    filterCheckboxes.map(function (checkbox) {
        checkbox.addEventListener('click', function(e){
            var browserUrl      = new URL(location.href);
            var formUrl         = new URL(filterForm.action);
            var formData        = new FormData(filterForm);
            var filterArray     = {};
            var filterCheckbox  = e.target;
            var relatedCheckbox = document.querySelectorAll('input[name="'+e.target.name+'"]:not([id="'+e.target.id+'"])')[0];
            
            
            filteredProductCount.map(function (span) {
                span.innerHTML = '';
                span.classList.add('d-none');
                span.classList.remove('d-block-inline');
            });
            

            if(!filterCheckbox.checked)
            {
                var filterName = filterCheckbox.name.slice(0, filterCheckbox.name.indexOf('['));

                var otherFilterCheckboxes = filterForm.querySelectorAll('[name^="'+filterName+'"]:checked');
                if(otherFilterCheckboxes.length < 1)
                {
                    browserUrl.searchParams.delete(filterName);
                    formUrl.searchParams.delete(filterName);
                    history.pushState(null, '', decodeURIComponent(browserUrl));
                }

                relatedCheckbox.checked = false;
            }
            else
            {
                relatedCheckbox.checked = true;
            }

            for (const pair of formData.entries())
            {
                if(!pair[0].includes('_'))
                {
                    var filterName = pair[0].slice(0, pair[0].indexOf('['));
                    var filterValue = pair[0].slice(pair[0].indexOf('[')+1).slice(0, -1);

                    if (!filterArray[filterName]) {
                        filterArray[filterName] = [];
                    }

                    filterArray[filterName].push(filterValue);
                }
            }

            

            for (const [filter, values] of Object.entries(filterArray))
            {
                browserUrl.searchParams.set(filter, values);
                formUrl.searchParams.set(filter, values);
            }

            history.pushState(null, '', decodeURIComponent(browserUrl));

            filterForm.action = decodeURIComponent(formUrl);

            window.axios
                .post(filterForm.action, formData)
                .then(function (response)
                {
                    var productContainer = document.getElementById('product-container');
                    // if(response.data.html && productContainer)
                    if(productContainer)
                    {
                        productContainer.innerHTML = response.data.html;
                    }

                    
                    filteredProductCount.map(function (span) {
                        span.innerHTML = response.data.count;
                        span.classList.remove('d-none');
                        span.classList.add('d-block-inline');
                    });

                })
                .catch(error => {})
                .then(function(){});

        });
    });

});