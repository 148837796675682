var mediaModals = [].slice.call(document.getElementsByClassName('media-modal'));

mediaModals.map(function (modal) {
    bootstrap.Modal.getInstance(modal);
    modal.addEventListener('show.bs.modal', event => {
        const button = event.relatedTarget
        const mediaType = button.getAttribute('data-media-type')
        const mediaSrc = button.getAttribute('data-media-src')
        
        if(mediaType === 'image'){
            const image = modal.getElementsByTagName('img')[0];
            image.src = mediaSrc;
            image.classList.remove('d-none');
        }
        else if(mediaType === 'video'){
            const iframe = modal.getElementsByTagName('iframe')[0];
            iframe.src = mediaSrc;
            iframe.classList.remove('d-none');
        }
    });


    modal.addEventListener('hidden.bs.modal', event => {
        const image  = modal.getElementsByTagName('img')[0];
        const iframe = modal.getElementsByTagName('iframe')[0];
        image.classList.add('d-none');
        iframe.classList.add('d-none');
    });

});
