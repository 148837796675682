document.addEventListener('click', function(e){


/**
 * Add product to Shopping Cart
 */
    if(e.target && e.target.closest('[type=submit]') && e.target.closest('form') && e.target.closest('form').classList.contains('shopping-cart-add-product-btn-form'))
    {
        e.preventDefault();

        var form         = e.target.closest('form');
        var submitBtn    = form.querySelectorAll('button[type=submit]')[0];
        var formData     = new FormData(form);
        var shoppingCart = document.getElementById('shopping-cart-offcanvas');

        submitBtn.disabled = true;

        window.axios
            .post(form.action, formData)
            .then(function (response)
            {
                var quantity  = response.data.quantity;
                var amount    = response.data.amount;
                var amountStr = response.data.amountStr;
                var items     = response.data.items;

                updateShoppingCartContent(shoppingCart, items);
                updateShoppingCartCount(quantity);
                updateShoppingCartAmount(amount, amountStr);
                toggleProceedBtn(shoppingCart, quantity);
                
                var bsOffcanvas = new bootstrap.Offcanvas(shoppingCart)
                bsOffcanvas.show();
            })
            .catch(error => {
                // alert(error.response.data.message);
            })
            .then(function(){
                submitBtn.disabled = false;
            });
    }



/**
 * Remove product from Shopping Cart
 */
    else if(e.target && e.target.closest('[type=submit]') && e.target.closest('form') && e.target.closest('form').classList.contains('shopping-cart-item-remove-btn-form'))
    {
        e.preventDefault();

        var form         = e.target.closest('form');
        var submitBtn    = form.querySelectorAll('button[type=submit]')[0];
        var formData     = new FormData(form);
        var shoppingCart = document.getElementById('shopping-cart-offcanvas');
        var confirm      = submitBtn.dataset.confirm;
        // var confirm      = e.target.dataset.confirm;

        if(window.confirm(confirm))
        {
            submitBtn.disabled = true;

            window.axios
                .post(form.action, formData)
                .then(function (response)
                {
                    var quantity  = response.data.quantity;
                    var amount    = response.data.amount;
                    var amountStr = response.data.amountStr;
                    var items     = response.data.items;

                    updateShoppingCartContent(shoppingCart, items);
                    updateShoppingCartCount(quantity);
                    updateShoppingCartAmount(amount, amountStr);
                    toggleProceedBtn(shoppingCart, quantity);
                })
                .catch(error => {})
                .then(function(){
                    submitBtn.disabled = false;
                });
        }
    }
});




document.addEventListener('change', function(e){


/**
 * Change product quantity
 */
    if(e.target && e.target.closest('select') && e.target.closest('form') && e.target.closest('form').classList.contains('shopping-cart-quantity-select-form'))
    {
        e.preventDefault();

        var form         = e.target.closest('form');
        var selectList   = form.querySelectorAll('select')[0];
        var formData     = new FormData(form);
        var shoppingCart = document.getElementById('shopping-cart-offcanvas');

        selectList.disabled = true;

        window.axios
        .post(form.action, formData)
        .then(function (response)
        {
            var quantity  = response.data.quantity;
            var amount    = response.data.amount;
            var amountStr = response.data.amountStr;
            var items     = response.data.items;

            updateShoppingCartContent(shoppingCart, items);
            updateShoppingCartCount(quantity);
            updateShoppingCartAmount(amount, amountStr);
            toggleProceedBtn(shoppingCart, quantity);
        })
        .catch(error => {})
        .then(function(){
            selectList.disabled = false;
        });
    }

});





function updateShoppingCartContent(shoppingCart, html)
{
    var container       = shoppingCart.getElementsByClassName('shopping-cart-content-container')[0];
    container.innerHTML = html;
}


function updateShoppingCartCount(quantity)
{
    if(quantity !== undefined)
    {
        var counter = document.getElementsByClassName('shopping-cart-counter')[0];

        if(quantity > 0)
        {
            counter.innerHTML= quantity;
            counter.classList.remove('d-none');
        }
        else {
            counter.innerHTML = '';
            counter.classList.add('d-none');
        }
    }
}
function updateShoppingCartAmount(amount, amountStr)
{
    if(amount !== undefined)
    {
        var field = document.getElementById('shopping-cart-amount');

        if(amount > 0)
        {
            field.innerHTML= amountStr;
            field.parentNode.classList.remove('d-none');
        }
        else {
            field.innerHTML = '';
            field.parentNode.classList.add('d-none');
        }
    }
}


function toggleProceedBtn(shoppingCart, quantity)
{
    var proceedBtnContainer = shoppingCart.getElementsByClassName('proceed-btn-container')[0];

    if(quantity > 0)
    {
        proceedBtnContainer.classList.remove('d-none');
        proceedBtnContainer.classList.add('d-block');
    } 
    else
    {
        proceedBtnContainer.classList.remove('d-block');
        proceedBtnContainer.classList.add('d-none');
    }
}