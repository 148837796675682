document.addEventListener('click', function(e){



    /**
     * Add product to the Wishlist
     */
        if(e.target && e.target.closest('[type=submit]') && e.target.closest('form') && e.target.closest('form').classList.contains('wishlist-product-btn-form'))
        {
            e.preventDefault();
    
            var form      = e.target.closest('form');
            var submitBtn = form.querySelectorAll('button[type=submit]')[0];
            var formData  = new FormData(form);
    
            submitBtn.disabled = true;
            
            window.axios
                .post(form.action, formData)
                .then(function (response)
                {
                    var quantity = response.data.quantity;
                    var mutation = response.data.mutation;
                    var items    = response.data.items;
    
                    updateProductWishlistForms(form.dataset.productId, mutation)
                    updateWishlistContent(items);
                    updateWishlistCount(quantity);
                })
                .catch(error => {})
                .then(function(){
                    submitBtn.disabled = false;
                });
        }
    
    
    
    /**
     * Transfer product from the Wishlist to Shopping cart
     */
        else if(e.target && e.target.closest('[type=submit]') && e.target.closest('form') && e.target.closest('form').classList.contains('wishlist-item-transfer-btn-form'))
        {
            e.preventDefault();
    
            var form      = e.target.closest('form');
            var submitBtn = form.querySelectorAll('button[type=submit]')[0];
            var formData  = new FormData(form);
    
            submitBtn.disabled = true;
    
            window.axios
                .post(form.action, formData)
                .then(function (response)
                {
                    var quantity               = response.data.quantity;
                    var items                  = response.data.items;
                    var shopping_cart_items    = response.data.shopping_cart.items;
                    var shopping_cart_quantity = response.data.shopping_cart.quantity;
                    var productId              = response.data.product_id;
        
                    updateProductWishlistForms(productId, 'add');
                    updateWishlistContent(items);
                    updateWishlistCount(quantity);
    
                    // Update shopping cart
                    var shoppingCart = document.getElementById('shopping-cart-offcanvas');
                    updateShoppingCartContent(shoppingCart, shopping_cart_items);
                    updateShoppingCartCount(shopping_cart_quantity);
                    toggleProceedBtn(shoppingCart, shopping_cart_quantity);
    
                })
                .catch(error => {
                    alert(error.response.data.message);
                })
                .then(function(){
                    submitBtn.disabled = false;
                });
        }
    
    
    
    /**
     * Remove product from the Wishlist
     */
        else if(e.target && e.target.closest('[type=submit]') && e.target.closest('form') && e.target.closest('form').classList.contains('wishlist-item-remove-btn-form'))
        {
            e.preventDefault();
    
            var form      = e.target.closest('form');
            var submitBtn = form.querySelectorAll('button[type=submit]')[0];
            var formData  = new FormData(form);
    
            submitBtn.disabled = true;
    
            window.axios
                .post(form.action, formData)
                .then(function (response)
                {
                    var quantity  = response.data.quantity;
                    var items     = response.data.items;
                    var productId = response.data.product_id;
    
                    updateProductWishlistForms(productId, 'add');
                    updateWishlistContent(items);
                    updateWishlistCount(quantity);
                })
                .catch(error => {})
                .then(function(){
                    submitBtn.disabled = false;
                });
        }
    
    });
    
    
    
    function updateProductWishlistForms(productId, mutation)
    {
        var wishlistForms = [].slice.call(document.querySelectorAll('form.wishlist-product-btn-form[data-product-id="'+productId+'"]'));
        wishlistForms.map(function (wishlistForm) {
            var submitBtn       = wishlistForm.querySelectorAll('button[type=submit]')[0];
            var icon            = submitBtn.getElementsByTagName('i')[0];
            var mutationElement = wishlistForm.querySelectorAll('input[name=mutation]')[0];
    
            toggleWishlistHeart(icon);
            mutationElement.value = mutation;
        });
    }
    
    
    
    function updateWishlistContent(html)
    {
        var wishlist  = document.getElementById('wishlist-offcanvas');
        var container = wishlist.getElementsByClassName('offcanvas-body')[0];
        container.innerHTML = html;
    }
    
    
    
    function updateWishlistCount(quantity)
    {
        if(quantity !== undefined)
        {
            var counter = document.getElementsByClassName('wishlist-counter')[0];
    
            if(quantity > 0)
            {
                counter.innerHTML= quantity;
                counter.classList.remove('d-none');
            }
            else {
                counter.innerHTML = '?';
                counter.classList.add('d-none');
            }
        }
    }
    
    
    
    function toggleWishlistHeart(icon)
    {
        if(icon.classList.contains('fa-regular'))
        {
            icon.classList.remove('fa-regular');
            icon.classList.add('fa-solid');
        } 
        else
        {
            icon.classList.remove('fa-solid');
            icon.classList.add('fa-regular');
        }
    }
    
    
    
    
    
    
    
    function updateShoppingCartContent(shoppingCart, html)
    {
        var container       = shoppingCart.getElementsByClassName('shopping-cart-content-container')[0];
        container.innerHTML = html;
    }
    
    
    function updateShoppingCartCount(quantity)
    {
        if(quantity !== undefined)
        {
            var counter = document.getElementsByClassName('shopping-cart-counter')[0];
    
            if(quantity > 0)
            {
                counter.innerHTML= quantity;
                counter.classList.remove('d-none');
            }
            else {
                counter.innerHTML = '?';
                counter.classList.add('d-none');
            }
        }
    }
    
    
    function toggleProceedBtn(shoppingCart, quantity)
    {
        var proceedBtnContainer = shoppingCart.getElementsByClassName('proceed-btn-container')[0];
    
        if(quantity > 0)
        {
            proceedBtnContainer.classList.remove('d-none');
            proceedBtnContainer.classList.add('d-block');
        } 
        else
        {
            proceedBtnContainer.classList.remove('d-block');
            proceedBtnContainer.classList.add('d-none');
        }
    }