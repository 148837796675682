// const inputElements = [...document.querySelectorAll('input.code-input')]

// inputElements.forEach((ele,index)=>{
//   ele.addEventListener('keydown',(e)=>{
//     // if the keycode is backspace & the current field is empty
//     // focus the input before the current. Then the event happens
//     // which will clear the "before" input box.
//     if(e.keyCode === 8 && e.target.value==='') inputElements[Math.max(0,index-1)].focus()
//   })
//   ele.addEventListener('input',(e)=>{
//     // take the first character of the input
//     // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
//     // but I'm willing to overlook insane security code practices.
//     // const [first,...rest] = e.target.value
//     const [f,s,t,...rest] = e.target.value
//     first = f+s+t;
//     console.log(f+s+t);
//     // e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
//     e.target.value = f+s+t ?? '' // first will be undefined when backspace was entered, so set the input to ""
//     const lastInputBox = index===inputElements.length-1
//     const didInsertContent = first!==undefined
//     if(didInsertContent && !lastInputBox) {
//       // continue to input the rest of the string
//       inputElements[index+1].focus()
//       inputElements[index+1].value = rest.join('')
//       inputElements[index+1].dispatchEvent(new Event('input'))
//     }
//   })
// })


// // mini example on how to pull the data on submit of the form
// function onSubmit(e){
//   e.preventDefault()
//   const code = inputElements.map(({value})=>value).join('')
//   console.log(code)
// }



// function OTPInput() {
//     const inputs = document.querySelectorAll('#otp > *[id]');
//     for (let i = 0; i < inputs.length; i++) {
//       inputs[i].addEventListener('keydown', function(event) {
//         if (event.key === "Backspace") {
//           inputs[i].value = '';
//           if (i !== 0)
//             inputs[i - 1].focus();
//         } 
//         else
//         {
// console.log(inputs[i].maxLength);
// console.log(inputs[i].value.length);

//             if (i === inputs.length - 1 && inputs[i].value !== '') {
//             // if (i === inputs.length - 1 && inputs[i].value.length < inputs[i].maxLength) {
//                 return true;
                
//             }
//             else if (event.keyCode > 47 && event.keyCode < 58)
//             {
//                 inputs[i].value = event.key;
//                 if (i !== inputs.length - 1)
//                 inputs[i + 1].focus();
//                 event.preventDefault();

//             }
//             else if (event.keyCode > 64 && event.keyCode < 91)
//             {
//                 inputs[i].value = String.fromCharCode(event.keyCode);

//                 // if (i !== inputs.length - 1)
//                 if((i !== inputs.length - 1 && inputs[i].value.length >= inputs[i].maxLength-1))
//                     inputs[i + 1].focus();
            
//                     event.preventDefault();
//           }
//         }
//       });
//     }
//   }
//   OTPInput();




// var elementExists = document.getElementById("coupon-code");

// if (elementExists){
//              document.getElementById('_phoneVal').addEventListener('input', function (e) {
//               var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
//               e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
//             });
//         }





var couponModal = document.getElementById('coupon-modal');

if(couponModal)
{
    var addCouponForm    = document.getElementById('add-coupon-form');
    var removeCouponForm = document.getElementById('remove-coupon-form');

    if(addCouponForm)
    {
        addCouponForm.addEventListener('submit', function(e){
            e.preventDefault();
            var form = this;
            var submitBtn = form.querySelectorAll('button[type=submit]')[0];
            var formData  = new FormData(form);

            submitBtn.disabled = true;
            removeErrors(form);

            window.axios
                .post(form.action, formData)
                .then(function (response)
                {
                    window.location.reload();
                })
                .catch(error => {
                    processErrors(form, error.response.data.errors);
                })
                .then(function(){
                    submitBtn.disabled = false;
                });
        });
    }

    // if(removeCouponForm)
    // {
    //     removeCouponForm.addEventListener('submit', function(e){
    //         e.preventDefault();
    //         var form = this;
    //         var submitBtn = form.querySelectorAll('button[type=submit]')[0];
    //         var formData  = new FormData(form);

    //         submitBtn.disabled = true;
    //         removeErrors(form);

    //         window.axios
    //             .post(form.action, formData)
    //             .then(function (response)
    //             {
                    
    //             })
    //             .catch(error => {
    //                 processErrors(form, error.response.data.errors);
    //             })
    //             .then(function(){
    //                 submitBtn.disabled = false;
    //             });
    //     });
    // }
}




function processErrors(form, errors)
{
    var cnt=0;
    for (const [fieldName, errorMessage] of Object.entries(errors))
    {
        // replace the .-part with []
        if(fieldName.indexOf('.') !== -1){
            fieldName = fieldName.split('.').join('[') +']';
        }

        // Get input element:
        const inputElement = form.querySelector('input[name="'+fieldName+'"]');

        if(inputElement)
        {
            // Add invalid class
            inputElement.classList.add('is-invalid');

            // Create message:
            const messageDiv = document.createElement('div')
            messageDiv.className = 'invalid-feedback';
            messageDiv.innerHTML = errorMessage;

            // Insert message div:
            inputElement.parentNode.insertBefore(messageDiv, inputElement.nextSibling);

            if(cnt < 1){
                inputElement.scrollIntoView();
            }

            cnt++;
        }
    }
}



function removeErrors(form){
    var validationMessages = [].slice.call(form.getElementsByClassName('invalid-feedback'));
    validationMessages.map(function(div){
        div.previousElementSibling.classList.remove('is-invalid')
        div.remove();
    });
}