var oneAddressCheck = document.getElementById('billing[is-delivery-address]');

if(oneAddressCheck)
{
    // toggleBillingAddressContainer(oneAddressCheck);

    oneAddressCheck.addEventListener('change', function(e){
        toggleBillingAddressContainer(e.target);
    });

}



function toggleBillingAddressContainer(oneAddressCheck)
{
    var billingAddressContainer = document.getElementById('checkout-billing-address-container');

    if(!oneAddressCheck.checked) {
        billingAddressContainer.classList.remove('d-none');
        var modalBody = billingAddressContainer.closest('.modal-body');
        modalBody.scrollTop = modalBody.scrollHeight;
    }
    else {
        billingAddressContainer.classList.add('d-none');
    }
}





const termsModal = document.getElementById('terms-and-conditions-modal')


if(termsModal)
{
    termsModal.addEventListener('show.bs.modal', event => {
    
        const loadTermsUrl = termsModal.dataset.url;

        window.axios
            .get(loadTermsUrl)
            .then(function (response)
            {
                const modalBody = termsModal.querySelector('.modal-body');
                modalBody.innerHTML = response.data;
            })
            .catch(error => {})
            .then(function(){});

    });

}










// document.addEventListener('click', function(e){


//     /**
//      * Submit form 1
//      */
//         if(e.target && e.target.type === 'submit' && e.target.getAttribute('form') === 'checkout-step1-form__')
//         {
//             e.preventDefault();
    
//             var form         = document.getElementById('checkout-step1-form');
//             var submitBtn    = e.target;
//             var formData     = new FormData(form);

//             submitBtn.disabled = true;
//             removeErrors(form);

//             window.axios
//                 .post(form.action, formData)
//                 .then(function (response)
//                 {
//                     const modalBody = form.closest('.modal-body');
//                     modalBody.innerHTML = response.data.html;
//                 })
//                 .catch(error => {
//                     processErrors(form, error.response.data.errors);
//                     // return Promise.reject(error);
//                 })
//                 .then(function(){
//                     submitBtn.disabled = false;
//                 });
//         }

// });


// function removeErrors(form){
//     var validationMessages = [].slice.call(form.getElementsByClassName('invalid-feedback'));
//     validationMessages.map(function(div){
//         div.previousElementSibling.classList.remove('is-invalid')
//         div.remove();
//     });
// }


// function processErrors(form, errors)
// {
//     var cnt=0;
//     for (const [fieldName, errorMessage] of Object.entries(errors))
//     {
//         // replace the .-part with []
//         if(fieldName.indexOf('.') !== -1){
//             fieldName = fieldName.split('.').join('[') +']';
//         }

//         // Get input element:
//         const inputElement = form.querySelector('input[name="'+fieldName+'"]');

//         if(inputElement)
//         {
//             // Add invalid class
//             inputElement.classList.add('is-invalid');

//             // Create message:
//             const messageDiv = document.createElement('div')
//             messageDiv.className = 'invalid-feedback';
//             messageDiv.innerHTML = errorMessage;

//             // Insert message div:
//             inputElement.parentNode.insertBefore(messageDiv, inputElement.nextSibling);

//             if(cnt < 1){
//                 inputElement.scrollIntoView();
//             }

//             cnt++;
//         }
//     }
// }