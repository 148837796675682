require('./bootstrap');



window.onload = (event) => {
    require('./files/default');
    require('./files/checkout');
    require('./files/coupons');
    require('./files/shopping-cart');
    require('./files/toaster');
    require('./files/media');
    require('./files/product-filters');
    require('./files/product-options');
    require('./files/wishlist');

}


window.onpageshow = (event) => {
    var preventMultipleSubmitForms = [].slice.call(document.querySelectorAll('form.prevent-multiple-submit'));

    preventMultipleSubmitForms.map(function (form) {
        var submitBtn = form.querySelectorAll('button[type="submit"]')[0];

        if(submitBtn === undefined){
            submitBtn = document.querySelector('button[type="submit"][form="'+form.id+'"]');
        }

        var spinner = submitBtn.querySelectorAll('.spinner')[0];
        if(spinner !== undefined){
            spinner.classList.add('d-none');
        }

        submitBtn.disabled = false;
    });
 };

// window.onload = (event) => {

//     require('./files/default');
//     require('./files/toaster');
//     require('./files/shopping-cart');

// }


// window.onpageshow = (event) => {
//     var preventMultipleSubmitForms = [].slice.call(document.querySelectorAll('form.prevent-multiple-submit'));

//     preventMultipleSubmitForms.map(function (form) {
//         var submitBtn = form.querySelectorAll('button[type="submit"]')[0];
//         var spinner = submitBtn.querySelectorAll('.spinner')[0];
//         spinner.classList.add('d-none');
//         submitBtn.disabled = false;
//     }); 
// };