var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
return new bootstrap.Tooltip(tooltipTriggerEl)
});


scrollToInvalidField();



function scrollToInvalidField()
{
    const invalidInputs = Array.from(document.querySelectorAll(':invalid, .is-invalid'));

    if(invalidInputs.length>0)
    {
        invalidInputs[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
        invalidInputs[0].focus();
    }
}






var preventMultipleSubmitForms = [].slice.call(document.querySelectorAll('form.prevent-multiple-submit'))

preventMultipleSubmitForms.map(function (form) {

    var submitBtn = form.querySelectorAll('button[type="submit"]')[0];

    if(submitBtn === undefined){
        submitBtn = document.querySelector('button[type="submit"][form="'+form.id+'"]');
    }

    form.addEventListener('submit', function(e){
        submitBtn.disabled = true;
        var spinner = submitBtn.querySelectorAll('.spinner')[0];
        spinner.classList.remove('d-none');
    });

});